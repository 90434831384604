<template>
  <div>
    <HeadBar />
    <nav class="navbar" id="navbar">
      <div
        class="container relative flex flex-wrap items-center justify-between"
      >
        <router-link to="/" class="navbar-brand md:me-8">
          <span class="inline-block dark:hidden">
            <img
              src="../assets/images/astrowarp-logo_white.svg"
              class="l-dark logo"
              alt=""
            />
            <img
              src="../assets/images/astrowarp-logo.svg"
              class="l-light logo"
              alt=""
            />
          </span>
          <img
            src="../assets/images/astrowarp-logo_white.svg"
            class="hidden dark:inline-block logo"
            alt=""
          />
        </router-link>

        <div class="nav-icons flex items-center lg_992:order-2 ms-auto lg:ms-4">
          <!-- Navbar Button -->
          <!-- <ul class="list-none menu-social mb-0">
                        <li class="inline">
                            <router-link to="/login" href="#" class="size-9 inline-flex items-center text-center justify-center text-base font-normal tracking-wide border align-middle transition duration-500 ease-in-out rounded-full bg-blue-600 hover:bg-blue-700 border-blue-600 hover:border-blue-700 text-white"><i class="uil uil-user"></i></router-link>
                        </li>
                    </ul> -->
          <!-- Navbar Collapse Manu Button -->
          <button
            data-collapse="menu-collapse"
            type="button"
            class="collapse-btn inline-flex items-center ms-2 text-dark dark:text-white lg_992:hidden"
            aria-controls="menu-collapse"
            aria-expanded="false"
            @click="handler"
          >
            <span class="sr-only">Navigation Menu</span>
            <i class="mdi mdi-menu text-[24px]"></i>
          </button>
        </div>

        <!-- Navbar Manu -->

        <div
          class="navigation lg_992:order-1 lg_992:flex ms-auto"
          :class="{ hidden: !toggle }"
          id="menu-collapse"
        >
          <ul class="navbar-nav nav-light" id="navbar-navlist">
            <router-link to="/" active-class="active">
              <li class="nav-item ms-0 home">
                <a class="nav-link" href="#home">Home</a>
              </li>
            </router-link>

            <router-link to="/feature" active-class="active">
              <li class="nav-item ms-0 feature">
                <a class="nav-link" href="#feature">Feature</a>
              </li>
            </router-link>

            <!-- <router-link to="/aboutus">
                        <li class="nav-item navbar-item about">
                            <a class="nav-link" href="#about">About</a>
                        </li>
                    </router-link> -->
            <router-link to="/usecase" active-class="active">
              <li class="nav-item ms-0 usecase">
                <a class="nav-link" href="#usecase">Use Case</a>
              </li>
            </router-link>
            <router-link to="/shop" active-class="active">
              <li class="nav-item ms-0 services">
                <a class="nav-link" href="#services">Shop</a>
              </li>
            </router-link>

            <!-- <router-link to="/pricing">
                        <li class="nav-item ms-0 pricing">
                            <a class="nav-link" href="#pricing">Pricing</a>
                        </li>
                    </router-link> -->
            <!-- <router-link to="/"> -->

            <!-- </router-link> -->
            <!-- <router-link to="/blogs"> -->
            <!-- blog -->
            <li class="nav-item ms-0">
              <a
                class="nav-link"
                target="_blank"
                href="https://docs.astrowarp.net/"
                >Docs</a
              >
            </li>
            <!-- </router-link> -->
            <!-- <li class="nav-item ms-0">
              <a
                class="nav-link"
                href="https://docs.astrowarp.net/en/"
                target="_blank"
                >Docs</a
              >
            </li> -->
            <li
              class="nav-item ms-0 support"
              @mouseover="supportExpand = true"
              @mouseleave="supportExpand = false"
            >
              <span class="nav-link"
                >Community
                <i v-if="supportExpand" class="fa fa-chevron-up"></i>
                <i v-else class="fa fa-chevron-down"></i>
              </span>
              <ul
                class="support-list"
                :class="supportExpand ? 'active' : ''"
                v-if="supportExpand"
              >
                <li>
                  <a href="/blogs">Blog</a>
                </li>

                <li>
                  <a target="_blank" href="https://forum.gl-inet.com/">Forum</a>
                </li>
                <li>
                  <a target="_blank" href="https://link.gl-inet.com/AW-homepage-discord"
                    >Discord</a
                  >
                </li>
              </ul>
            </li>

            <!-- <router-link to="/contact">
              <li class="nav-item ms-0 contact">
                <a class="nav-link" href="#contact">Contact us</a>
              </li>
            </router-link> -->
            <router-link to="/download" active-class="active">
              <li class="nav-item ms-0 download">
                <a class="nav-link" href="#download">Download</a>
              </li>
            </router-link>
            <li class="nav-item ms-0 contact log-in cursor-pointer">
              <span class="nav-link flex"
                ><a
                  href="https://my.astrowarp.net/"
                  class="sign-a"
                  target="_blank"
                  >Get Started</a
                >
              </span>
            </li>
            <!-- <li
              class="nav-item ms-0 contact log-in cursor-pointer">
              <span class="nav-link"
                ><a
                  href="https://my.astrowarp.net/"
                  class="log-a"
                  target="_blank"
                  >Log in</a
                >
                <a
                  href="https://my.astrowarp.net/#/signup"
                  class="sign-a"
                  target="_blank"
                  >Sign up</a
                >
                
              </span>
            </li> -->
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
// This may come from a CMS, markdown file, etc.
import HeadBar from "../components/HeadBar.vue";

export default {
  name: "navbarVue",
  components: { HeadBar },
  data() {
    return {
      toggle: false,
      scroll: true,
      sections: document.querySelectorAll("section"),
      navLi: document.querySelectorAll("nav .container .navigation ul li"),
      current: "",
      sectionTop: null,
      supportExpand: false,
      showBtn: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("scroll", this.onscroll);
    const home = document.getElementById("home");
    if (!home) {
      this.showBtn = true;
    }
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("scroll", this.onscroll);
  },

  methods: {
    handler: function () {
      this.toggle = !this.toggle;
    },
    handleScroll() {
      const navbar = document.getElementById("navbar");
      // const home = document.getElementById("home");
      if (
        document.body.scrollTop >= 51 ||
        document.documentElement.scrollTop >= 51
      ) {
        navbar.classList.add("is-sticky");
        navbar.style.top = "0";
      } else {
        navbar.classList.remove("is-sticky");
        navbar.style.top = "40px";
      }

      // if(home){
      //   var height = home.offsetHeight-200;
      // if (
      //   document.body.scrollTop >= height ||
      //   document.documentElement.scrollTop >= height
      // ) {
      //   this.showBtn = true
      // } else {
      //   this.showBtn = false
      // }
      // }else{
      //   this.showBtn = true
      // }
    },

    // onscroll() {
    //   document.querySelectorAll("section").forEach((section) => {
    //     this.sectionTop = section.offsetTop;
    //     if (pageYOffset >= this.sectionTop - 60) {
    //       this.current = section.getAttribute("id");
    //     }
    //   });
    //   document
    //     .querySelectorAll("nav .container .navigation ul li")
    //     .forEach((li) => {
    //       if (li.classList.contains(this.current)) {
    //         li.classList.add("active");
    //       } else {
    //         li.classList.remove("active");
    //       }
    //     });
    // },
  },
};
</script>
<style lang="scss" scoped>
.navbar .navigation .navbar-nav.nav-light .nav-link {
  color: #D9D9FF;
  font-weight: normal;
  font-size: 16px;
  
}

.nav-link:hover{
  color: #ffffff!important;
}
.navbar-nav {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}
#navbar {
  top: 40px;
}

.login_btn {
  color: #fff;
  position: absolute;
  right: 0;
  /* top: 6px; */
  /* line-height: 48px; */
  // border: 2px solid #fff;
  border-radius: 35px;
  // padding: 4px 18px;
  margin-top: 10px;
}
.login_btn:hover {
  text-decoration: underline;
}
.is-sticky .login_btn {
  color: #000;
  // border: 2px solid #000;
}
.log-in {
  a {
    padding: 0 16px;
    border: 2px solid #7c3aed;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    border-radius: 100px;
    display: inline-block;
    height: 30px;
    line-height: 26px;
    letter-spacing: 0.5px;
    background: #ffffff;
    // box-shadow: 2px 2px -2px 2px #7c3aed50;
    color: #7c3aed;
    position: relative;
    overflow: hidden;
    font-weight: 600;
    font-size: 14px;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
  }

  .log-a {
    border: 1px solid #7c3aed;
    background: #fff;
    color: #7c3aed;
    margin-right: 10px;
  }
}
.support {
  position: relative;
  cursor: pointer;
}
.support-list {
  background-color: #fff;
  color: #333150;
  padding: 4px 8px;
  border-radius: 4px;
  left: 10px;
  position: absolute;
  width: 120px;
  box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  overflow: hidden;
  top: 74px;
  opacity: 0;
  transform: translateY(-2em);
  visibility: hidden;
  z-index: -1;
  transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s,
    z-index 0s linear 0.01s;
  li {
    margin: 0;
    display: block;
    background: #fff;
    color: #333150;
    padding-left: 20px;
    height: 40px;
    line-height: 40px;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    position: relative;
    font-size: 13px;
    border-bottom: 1px solid #f5f5f5;
  }
  li:hover {
    text-decoration: underline;
  }
}
.active {
  opacity: 1;
  z-index: 1000;
  visibility: visible;
  transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s,
    z-index 0s linear 0.01s;
  a {
    color: #7c3aed !important;
  }
}
.is-sticky {
  @media (min-width: 1024px)  {
    padding: 0;
   }
 
  .support-list {
    border: 1px solid #7c3aed;
  }
}
</style>