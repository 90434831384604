<template>
  <div>
    <section class="relative md:py-24 py-16 bg-[#131419] text-white" id="home">
      <div class="container mx-auto">
        <div
          class="flex flex-col justify-center"
        >
            <div class="mb-10 lg:ms-7 ">
              <h3 class="mb-4 text-[22px] lg:text-5xl  font-bold text-center">
                Flexible and Secure Networking Solutions
              </h3>

              <p class="text-slate-400  mb-4 mt-6  text-lg  text-center dark:text-slate-300 mx-auto md:px-20">
                AstroWarp is an advanced networking platform designed to provide
                seamless remote networking and remote device management. Built
                specifically for GL.iNet router integration, AstroWarp supports
                comprehensive device management across entire networks, enabling
                both upper and lower device control. With a focus on
                network-wide management and future support for hardware-level
                control, AstroWarp offers a more robust and dependable solution
                for managing devices and maintaining secure, stable networks.
              </p>

              <!-- <div class="relative mt-8">
                                <router-link to="/aboutus"
                                    class="py-2 px-5 inline-block font-normal tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-blue-600 hover:bg-blue-900 border-blue-600 hover:border-blue-700 text-white rounded-md">Read
                                    More <i class="uil uil-arrow-right"></i></router-link>
                            </div> -->
            </div>
            <div class="w-full flex justify-center">
            <img
              src="https://static.gl-inet.com/astrowarp/images/screen_2.png"
              class="rounded-lg relative"
              alt=""
            />
          </div>
          </div>
      </div>
    </section>
  </div>
</template>

<script>
import feather from "feather-icons";

export default {
  name: "AboutUs",
  data() {
    return {};
  },
  mounted() {
    feather.replace();
  },
};
</script>

<style lang="scss" scoped></style>