
<template>
    <div class="head-bar-tip">
     <a id="barHref" href="/shop/price" >Meet AstroWarp: Try It for Free</a>
</div>
</template>
<style lang="scss" scoped>
    body {
        padding-top: 105px !important;
    }

    .head-bar-tip {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 9999;
        text-align: center;
        height: 40px;
        line-height: 40px;
        background: #2562EA;
        // transparent linear-gradient(90deg, #B786FF 0%, #6609FC 100%) 0% 0% no-repeat padding-box;
        color: #fff;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .global-header {
        margin-top: 40px;
    }

    #barHref {
        padding: 0 6px;
        color: #fff;
        font-weight: 600;
    }

    #barHref:hover {
        text-decoration: underline;
    }

    @media(max-width: 767px) {
        .head-bar-tip {
            line-height: 40px;
            height: 40px;
            display: block;
            font-size: 14px;
            padding: 0 30px;
        }


    }
</style>

