<template>
  <div>
    <!--       v-show="showTopButton"
 -->
    <!-- <a
      href="#"
      @click="scrollToTop"
      id="back-to-top"
      class="fixed text-3xl rounded-full z-10 bottom-5 end-5 size-12  text-center bg-violet-600 text-white flex justify-center items-center"
      ><i class="uil uil-arrow-up"></i
    ></a> -->
    <!-- Back to top -->

    <!-- Switcher -->
    <!-- <div class="fixed bottom-5 end-2 z-3" v-show="!showTopButton">
      <span class="relative inline-block rotate-90">
        <input
          type="checkbox"
          class="checkbox opacity-0 absolute"
          id="chk"
          @change="changeMode($event)"
        />
        <label
          class="label bg-slate-900 dark:bg-white shadow dark:shadow-gray-800 cursor-pointer rounded-full flex justify-between items-center p-1 w-14 h-8"
          for="chk"
        >
          <i class="uil uil-moon text-[20px] text-violet-600"></i>
          <i class="uil uil-sun text-[20px] text-violet-300"></i>
          <span
            class="ball bg-white dark:bg-slate-900 rounded-full absolute top-[2px] left-[2px] size-7"
          ></span>
        </label>
      </span>
    </div> -->
    <!-- contact -->
    <span
      @click="showWindow"
      id="message-btn"
      class="cursor-pointer flex justify-center items-center size-16 fixed text-lg rounded-full z-10 bottom-2 mb-2 end-2 bg-violet-600 text-white leading-12 flex justify-center items-center"
    >
      <svg
        v-if="!showContactWindow"
        viewBox="0 0 1024 1024"
        width="32"
        height="32"
      >
        <path
          d="M311.082667 888.490667L85.333333 938.666667l50.176-225.749334A424.789333 424.789333 0 0 1 85.333333 512C85.333333 276.352 276.352 85.333333 512 85.333333s426.666667 191.018667 426.666667 426.666667-191.018667 426.666667-426.666667 426.666667a424.789333 424.789333 0 0 1-200.917333-50.176z m12.373333-90.154667l27.861333 14.933333A339.413333 339.413333 0 0 0 512 853.333333a341.333333 341.333333 0 1 0-341.333333-341.333333c0 56.917333 13.866667 111.701333 40.106666 160.682667l14.890667 27.861333-27.946667 125.738667 125.738667-27.946667z"
          fill="#ffffff"
        ></path>
      </svg>
      <svg viewBox="0 0 1221 1024" v-else width="28" height="28">
        <path
          d="M62.203 220.292c-32.366 30.737-32.366 80.567 0 111.305l497.841 472.112c32.366 30.737 84.875 30.737 117.243 0l497.726-472.112c16.183-15.367 24.334-35.51 24.334-55.652s-8.15-40.284-24.334-55.652c-32.483-30.737-84.875-30.737-117.358 0l-439.045 416.46-439.045-416.46c-32.366-30.737-84.875-30.737-117.358 0z"
          fill="#ffffff"
        ></path>
      </svg>
    </span>

    <transition name="fade">
      <div
        v-show="showContactWindow"
        id="message-win"
        class="fixed bottom-20 end-5 z-3 px-0"
       
      >
        <div
          class="bg-white dark:bg-slate-800 shadow dark:shadow-gray-700 px-4 py-2"
        >
          <h3 class="text-lg leading-normal font-medium text-center">
            Contact Us
          </h3>

          <form
            action="https://gl-inet.us16.list-manage.com/subscribe/post?u=8ed66b6ad326f7c2f2f6127f4&amp;id=24702471e1&amp;f_id=001730e0f0"
            method="post"
            name="mc-embedded-subscribe-form"
            class="text-center"
          >
            <!-- <div class="grid lg:grid-cols-12 lg:gap-6"> -->
            <div class="lg:col-span-6 mb-1">
              <input
                name="NAME"
                id="name"
                type="text"
                class="form-input w-full px-3 h-8 bg-slate-50 border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-violet-600/50 dark:focus:border-violet-600/50 focus:ring-0 mt-2"
                placeholder="Name :"
              />
            </div>

            <!-- </div> -->

            <div class="lg:col-span-6 mb-1">
              <input
                name="EMAIL"
                id="email"
                type="email"
                class="form-input w-full px-3 h-8 bg-slate-50 border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-violet-600/50 dark:focus:border-violet-600/50 focus:ring-0 mt-2"
                placeholder="Email :"
              />
            </div>
            <div class="grid grid-cols-1">
              <div class="mb-1">
                <input
                  name="SUBJECT"
                  id="subject"
                  class="form-input w-full px-3 h-8 bg-slate-50 border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-violet-600/50 dark:focus:border-violet-600/50 focus:ring-0 mt-2"
                  placeholder="Subject :"
                />
              </div>

              <div class="mb-1">
                <textarea
                  name="MESSAGE"
                  id="comments"
                  class="form-input w-full px-3 bg-slate-50 border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-violet-600/50 dark:focus:border-violet-600/50 focus:ring-0 mt-2 textarea h-12"
                  placeholder="Message :"
                ></textarea>
              </div>
            </div>
            <div class="grid grid-cols-1 text-left text-sm">
              <div class="mb-1">
                <label>Marketing Permissions</label>
              </div>
              <p class="mb-1">
                Please select all the information you would like to hear from
                AstroWarp:
              </p>
              <div class="mb-1">
                <fieldset
                  class="mc_fieldset gdprRequired mc-field-group"
                  name="interestgroup_field"
                >
                  <label class="checkbox subfield block" for="gdpr216882"
                    ><input
                      type="checkbox"
                      id="gdpr_216882"
                      name="gdpr[216882]"
                      class="mr-2"
                      value="Y"
                    /><span>Product News</span></label
                  ><label class="checkbox subfield block" for="gdpr216885"
                    ><input
                      type="checkbox"
                      id="gdpr_216885"
                      name="gdpr[216885]"
                      class="mr-2"
                      value="Y"
                    /><span>Blog &amp; Tutorials</span></label
                  ><label class="checkbox subfield block" for="gdpr216886"
                    ><input
                      type="checkbox"
                      id="gdpr_216886"
                      name="gdpr[216886]"
                      class="mr-2"
                      value="Y"
                    /><span>Discount and Special Offers</span></label
                  >
                </fieldset>
              </div>
              <p class="mb-1">
                You can unsubscribe at any time by clicking the link in the
                footer of our emails. For information about our privacy
                practices, please visit our website.
              </p>
            </div>
            <button
              type="submit"
              name="subscribe"
              id="submit"
              class="py-1 mt-2 px-5 inline-block font-normal tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white rounded-md"
            >
              Send Message
            </button>
          </form>
        </div>
      </div>
    </transition>
    <!-- LTR & RTL Mode Code 
        <div class="fixed top-[40%] -right-3 z-50">
            <a href="javascript:void(0)" id="switchRtl" @click="decrement">
                <span class="py-1 px-3 relative inline-block rounded-t-md -rotate-90 bg-white dark:bg-slate-900 shadow-md dark:shadow dark:shadow-gray-800 font-semibold rtl:block ltr:hidden" @click="changeThem($event)">LTR</span>
                <span class="py-1 px-3 relative inline-block rounded-t-md -rotate-90 bg-white dark:bg-slate-900 shadow-md dark:shadow dark:shadow-gray-800 font-semibold ltr:block rtl:hidden" @click="changeThem($event)">RTL</span>
            </a>
        </div>-->
  </div>
</template>

<script>
import feather from "feather-icons";
export default {
  name: "switcherVue",
  data() {
    return {
      htmlTag: document.getElementsByTagName("html")[0],
      showTopButton: false,
      showContactWindow: false,
    };
  },

  mounted() {
    feather.replace();
    this.htmlTag.className = "dark";
  },

  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },

  methods: {
    showWindow() {
      this.showContactWindow = !this.showContactWindow;
    },
    isScrolledToBottom() {
      // 获取窗口的高度
      const windowHeight =
        window.innerHeight || document.documentElement.clientHeight;

      // 获取窗口的垂直滚动位置
      const scrollY = window.scrollY || document.documentElement.scrollTop;

      // 获取文档的总高度
      const docHeight = document.documentElement.scrollHeight;

      // 判断是否滚动到底部
      return scrollY + windowHeight >= docHeight - 1; // 减去1是为了处理可能的浮点数精度问题
    },
    handleScroll() {
      let btn = document.getElementById("message-btn");
      console.log(this.isScrolledToBottom());
      let win = document.getElementById("message-win");
      if (this.isScrolledToBottom()) {
        btn.classList.remove("bottom-2");
        btn.classList.add("bottom-16");
        if (win) {
          win.classList.remove("bottom-20");
          win.classList.add("bottom-32");
        }
      } else {
        btn.classList.remove("bottom-16");
        btn.classList.add("bottom-2");
        if (win) {
          win.classList.remove("bottom-32");
          win.classList.add("bottom-20");
        }
      }
      //   if (
      //     document.body.scrollTop >= 50 ||
      //     document.documentElement.scrollTop >= 50
      //   ) {
      //     this.showTopButton = true;
      //   } else {
      //     this.showTopButton = false;
      //   }
      // },
      // changeThem(event) {
      //  if(event.target.innerText === "LTR"){
      //      this.htmlTag.dir = "ltr"
      //    }
      //    else{
      //         this.htmlTag.dir = "rtl"
      //     }
    },

    changeMode() {
      if (this.htmlTag.className.includes("light")) {
        this.htmlTag.className = "dark";
      } else {
        this.htmlTag.className = "light";
      }
    },

    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>
<style >
/* 定义动画 */
@keyframes grow {
  0% {
    opacity: 0;

    /* transform: scale(0);
    bottom: 0;
    right: 0; */
  }
  100% {
    opacity: 1;
    /* transform: scale(1); 
    bottom: 0;
    right: 0; */
  }
}
#message-win {
  width: 90%;
  max-width: 400px;
  padding-top: 20px;
  background-color: #7c3aed;
  border-radius: 2px;
  margin: 10px;
  overflow: auto;
  max-height: 520px;
  font-size: 14px !important;
  @media (max-width: 1440px) {
    font-size: 12px !important;
    bottom: 3.4rem !important;
    inset-inline-end: 0.25rem !important;
    max-height: 420px;
    .mt-2 {
      margin-top: 0 !important;
    }
    label,
    p {
      line-height: 1.2 !important;
      font-size: 12px !important;
    }
    h3 {
      font-size: 16px !important;
    }
  }
}
/* 当元素被插入到 DOM 中时应用的样式 */
.fade-enter-active {
  animation:grow 1s forwards;
}
 
/* 当元素从 DOM 中移除时应用的样式（Vue 2.1.8+ 使用 leave-to） */
.fade-leave-active {
  opacity: 0;
  transition: all 1s ease;
}
@media (max-width: 768px) {
  #message-btn {
    bottom: 0 !important;
  }
}
@media (max-width: 1440px) {
  #message-btn {
    width: 3rem !important;
    height: 3rem !important;
    inset-inline-end: 0.25rem !important;
  }
  .form-input::placeholder,
  .form-textarea::placeholder {
    font-size: 12px !important;
  }
}
@media (min-width: 769px) {
  #message-btn:hover {
    width: 3.2rem !important;
    height: 3.2rem !important;
  }
}
@media (min-width: 1441px) {
  #message-btn:hover {
    width: 4.2rem !important;
    height: 4.2rem !important;
  }
  .form-input::placeholder,
  .form-textarea::placeholder {
    font-size: 14px !important;
  }
}
/* 定义过渡效果 */

/* .fade-enter {
  opacity: 0;
}
.fade-enter-active {
  transition: all 1s ease;
} */
/* .fade-leave-active {
  transition: all 1s ease;
}
.fade-leave-to {
  opacity: 0;
} */
</style>