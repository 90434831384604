<template>
  <div>
    <footer
      class="footer bg-[#131419] relative text-violet-100 dark:text-violet-100"
    >
      <div class="container border-t border-[#4e4e53]">
        <div class="grid grid-cols-12">
          <div class="col-span-12">
            <div class="py-[40px] px-0">
              <div class="grid grid-cols-1">
                <!-- <div class="text-center">
                  <img
                    src="../assets/images/logo-icon-64.png"
                    class="block mx-auto"
                    alt=""
                  />
                  <p class="max-w-xl mx-auto text-slate-400 mt-8"></p>
                </div> -->

                <ul class="list-none footer-list text-left mt-8 md:flex">
                  <div class="flex-1">
                    <img
                      src="../assets/images/astrowarp-logo_white.svg"
                      class="hidden dark:inline-block logo"
                      alt=""
                    />
                    <p class="text-[16px] pt-4 text-[#ffffff]">
                      Flexible and Secure Networking Solutions.
                    </p>
                  </div>
                  <!-- <div class="flex-1">
                    <li class="text-[#ffffff50] font-bold">Community</li>
                    <li class="mt-[10px]">
                      <router-link
                        to="/blogs"
                        class="text-white hover:text-gray-400 duration-500 ease-in-out"
                        >Blog</router-link
                      >
                    </li>
                    <li class="mt-[10px]">
                      <a
                        target="_blank"
                        href="https://forum.gl-inet.com/"
                        class="text-white hover:text-gray-400 duration-500 ease-in-out"
                        >Forum</a
                      >
                    </li>
                    <li class="mt-[10px]">
                      <a
                        target="_blank"
                        href="https://link.gl-inet.com/AW-homepage-discord"
                        class="text-white hover:text-gray-400 duration-500 ease-in-out"
                        >Discord</a
                      >
                    </li>
                  </div> -->

                  <div class="flex-1 md:pl-32 text-[#ffffff]">
                    <li class="text-[#ffffff50] font-bold">Shop</li>
                    <li class="mt-[10px] ">
                      <router-link
                        to="/shop"
                        class=" hover:text-gray-400 duration-500 ease-in-out"
                        >Pricing</router-link
                      >
                    </li>
                    <li class="mt-[10px]">
                      <router-link
                        to="/shop/compatible-devices"
                        class=" hover:text-gray-400 duration-500 ease-in-out"
                        >Compatible Devices</router-link
                      >
                    </li>
                  </div>

                  <div class="flex-1  text-[#ffffff]">
                    <li class="text-[#ffffff50] font-bold">Resources</li>
                    <li class="mt-[10px]">
                      <a
                        target="_blank"
                        href="https://docs.astrowarp.net/"
                        class=" hover:text-gray-400 duration-500 ease-in-out"
                        >Docs</a
                      >
                    </li>
                    <li class="mt-[10px]">
                      <router-link
                        to="/security-notice"
                        class=" hover:text-gray-400 duration-500 ease-in-out"
                        >Security Notice</router-link
                      >
                    </li>
                    <li class="mt-[10px]">
                      <router-link
                        to="/privacy-policy"
                        class=" hover:text-gray-400 duration-500 ease-in-out"
                        >Privacy Policy</router-link
                      >
                    </li>
                    <li class="mt-[10px]">
                      <router-link
                        to="/service-agreement"
                        class=" hover:text-gray-400 duration-500 ease-in-out"
                        >User Service Agreement</router-link
                      >
                    </li>
                    <!-- <li class="mt-[10px]">
                      <router-link to="/contact">
                        <a class="text-white hover:text-gray-400 duration-500 ease-in-out" href="#contact">Contact us</a>
                      </router-link>
                    </li> -->
                  </div>

                  <div class="flex-1">
                    <div class="flex flex-col">
                      <div class="flex-1">
                        <h5
                          class="text-lg dark:dark:text-[#ffffff50] mb-2 font-bold"
                        >
                          <!-- <i
                            class="uil uil-envelope rounded text-2xl dark:text-[#ffffff50]"
                          ></i> -->
                          Email
                        </h5>
                        <a
                          href="mailto:support@astrowarp.net"
                          class="text-white"
                          >support@astrowarp.net</a
                        >
                      </div>
                      <div class="flex-1">
                        <h5
                          class="text-lg dark:text-[#ffffff50] mb-2 font-bold"
                        >
                          <!-- <i
                            class="uil uil-map-marker-alt rounded  text-2xl dark:text-[#ffffff50]"
                          ></i> -->
                          Location
                        </h5>
                        <p class="text-white mb-2">
                          GL Intelligence, Inc.<br />
                          10400 Eaton Place, Suite 215, <br />
                          Fairfax, VA 22030
                        </p>
                      </div>
                    </div>
                  </div>
                  <!-- <li class="inline px-2 mt-[10px]"><router-link to="/blogs" class="text-white hover:text-gray-400 duration-500 ease-in-out ms-2">Blogs</router-link></li>
                                    <li class="inline px-2 mt-[10px]"><a  target="_blank" href="https://docs.astrowarp.net/"  class="text-white hover:text-gray-400 duration-500 ease-in-out ms-2">Docs</a></li>
                                   
                                    <li class="inline px-2 mt-[10px]"><router-link to="/security-notice" class="text-white hover:text-gray-400 duration-500 ease-in-out ms-2">Security Notice</router-link></li>
                                    <li class="inline px-2 mt-[10px]"><router-link to="/privacy-policy" class="text-white hover:text-gray-400 duration-500 ease-in-out ms-2">Privacy Policy</router-link></li>
                                    <li class="inline px-2 mt-[10px]"><router-link to="/service-agreement" class="text-white hover:text-gray-400 duration-500 ease-in-out ms-2">User Service Agreement</router-link></li> -->
                </ul>
                <!--end icon-->
              </div>
              <!--end grid-->
            </div>
          </div>
        </div>
        <!--end grid-->

        <!--end container-->

        <div class="pb-[30px] pt-2 border-t border-[#4e4e53] w-full">
          <div class="container text-center">
            <div style="flex-direction: column;" class="flex  lg:flex-row justify-between items-center">
              <!-- <div class="md:col-span-8"> -->
                <div class="md:text-start text-center">
                  <p class="text-[#939393]">
                    © {{ date }} AstroWarp. Powered by
                    <a
                      href="https://gl-inet.com"
                      target="_blank"
                      class="text-reset"
                      >GL.iNet</a
                    >.
                  </p>
                </div>
                <ul class="list-none text-left">
                  <li class="inline pr-2">
                    <a
                      href="https://www.reddit.com/r/AstroWarp/"
                      target="_blank"
                      class="inline-flex text-white/60 items-center text-center justify-center text-base font-normal tracking-wide align-middle transition duration-500 ease-in-out"
                    >
                      <svg
                        class="text-white/60"
                        viewBox="0 0 1024 1024"
                        width="28"
                        height="28"
                      >
                        <path
                          d="M938.666667 490.666667A106.666667 106.666667 0 0 0 832 384a104.96 104.96 0 0 0-64 22.613333A486.4 486.4 0 0 0 551.68 341.333333l26.453333-151.466666a10.24 10.24 0 0 1 10.24-8.96h2.986667L725.333333 221.866667a85.333333 85.333333 0 1 0 17.92-61.44l-134.826666-39.253334a85.333333 85.333333 0 0 0-20.906667-2.986666 74.666667 74.666667 0 0 0-73.386667 61.866666L486.826667 341.333333A491.52 491.52 0 0 0 256 406.613333 104.96 104.96 0 0 0 192 384 106.24 106.24 0 0 0 128 576.853333 165.546667 165.546667 0 0 0 128 597.333333c0 141.226667 170.666667 256 384 256s384-114.773333 384-256a165.546667 165.546667 0 0 0 0-20.48 106.666667 106.666667 0 0 0 42.666667-86.186666z m-640 42.666666A64 64 0 1 1 362.666667 597.333333 64 64 0 0 1 298.666667 533.333333z m350.293333 189.44a21.76 21.76 0 0 1-10.666667 13.653334 268.373333 268.373333 0 0 1-252.586666 0 21.76 21.76 0 0 1-10.666667-13.653334 22.186667 22.186667 0 0 1 3.413333-17.066666l12.373334-17.493334A20.906667 20.906667 0 0 1 418.133333 682.666667a216.32 216.32 0 0 0 187.733334 0 20.906667 20.906667 0 0 1 27.306666 6.826666l12.373334 17.493334a22.186667 22.186667 0 0 1 3.413333 15.786666zM661.333333 597.333333a64 64 0 1 1 64-64 64 64 0 0 1-64 64z"
                          class="index-svg"
                        ></path>
                      </svg>
                      <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M0 256C0 114.6 114.6 0 256 0S512 114.6 512 256s-114.6 256-256 256L37.1 512c-13.7 0-20.5-16.5-10.9-26.2L75 437C28.7 390.7 0 326.7 0 256zM349.6 153.6c23.6 0 42.7-19.1 42.7-42.7s-19.1-42.7-42.7-42.7c-20.6 0-37.8 14.6-41.8 34c-34.5 3.7-61.4 33-61.4 68.4l0 .2c-37.5 1.6-71.8 12.3-99 29.1c-10.1-7.8-22.8-12.5-36.5-12.5c-33 0-59.8 26.8-59.8 59.8c0 24 14.1 44.6 34.4 54.1c2 69.4 77.6 125.2 170.6 125.2s168.7-55.9 170.6-125.3c20.2-9.6 34.1-30.2 34.1-54c0-33-26.8-59.8-59.8-59.8c-13.7 0-26.3 4.6-36.4 12.4c-27.4-17-62.1-27.7-100-29.1l0-.2c0-25.4 18.9-46.5 43.4-49.9l0 0c4.4 18.8 21.3 32.8 41.5 32.8zM177.1 246.9c16.7 0 29.5 17.6 28.5 39.3s-13.5 29.6-30.3 29.6s-31.4-8.8-30.4-30.5s15.4-38.3 32.1-38.3zm190.1 38.3c1 21.7-13.7 30.5-30.4 30.5s-29.3-7.9-30.3-29.6c-1-21.7 11.8-39.3 28.5-39.3s31.2 16.6 32.1 38.3zm-48.1 56.7c-10.3 24.6-34.6 41.9-63 41.9s-52.7-17.3-63-41.9c-1.2-2.9 .8-6.2 3.9-6.5c18.4-1.9 38.3-2.9 59.1-2.9s40.7 1 59.1 2.9c3.1 .3 5.1 3.6 3.9 6.5z"/></svg> -->
                    </a>
                  </li>

                  <li class="inline">
                    <a
                      href="https://link.gl-inet.com/AW-homepage-discord"
                      target="_blank"
                      class="inline-flex items-center text-center justify-center text-base font-normal tracking-wide align-middle transition duration-500 ease-in-out"
                    >
                      <svg viewBox="0 0 1280 1024" width="24" height="24">
                        <path
                          d="M1049.062 139.672a3 3 0 0 0-1.528-1.4A970.13 970.13 0 0 0 808.162 64.06a3.632 3.632 0 0 0-3.846 1.82 674.922 674.922 0 0 0-29.8 61.2 895.696 895.696 0 0 0-268.852 0 619.082 619.082 0 0 0-30.27-61.2 3.78 3.78 0 0 0-3.848-1.82 967.378 967.378 0 0 0-239.376 74.214 3.424 3.424 0 0 0-1.576 1.352C78.136 367.302 36.372 589.38 56.86 808.708a4.032 4.032 0 0 0 1.53 2.75 975.332 975.332 0 0 0 293.65 148.378 3.8 3.8 0 0 0 4.126-1.352A696.4 696.4 0 0 0 416.24 860.8a3.72 3.72 0 0 0-2.038-5.176 642.346 642.346 0 0 1-91.736-43.706 3.77 3.77 0 0 1-0.37-6.252 502.094 502.094 0 0 0 18.218-14.274 3.638 3.638 0 0 1 3.8-0.512c192.458 87.834 400.82 87.834 591 0a3.624 3.624 0 0 1 3.848 0.466 469.066 469.066 0 0 0 18.264 14.32 3.768 3.768 0 0 1-0.324 6.252 602.814 602.814 0 0 1-91.78 43.66 3.75 3.75 0 0 0-2 5.222 782.11 782.11 0 0 0 60.028 97.63 3.728 3.728 0 0 0 4.126 1.4A972.096 972.096 0 0 0 1221.4 811.458a3.764 3.764 0 0 0 1.53-2.704c24.528-253.566-41.064-473.824-173.868-669.082zM444.982 675.16c-57.944 0-105.688-53.174-105.688-118.478s46.818-118.482 105.688-118.482c59.33 0 106.612 53.64 105.686 118.478 0 65.308-46.82 118.482-105.686 118.482z m390.76 0c-57.942 0-105.686-53.174-105.686-118.478s46.818-118.482 105.686-118.482c59.334 0 106.614 53.64 105.688 118.478 0 65.308-46.354 118.482-105.688 118.482z"
                          class="index-svg"
                        ></path>
                      </svg>
                    </a>
                  </li>
                </ul>
              <!-- </div> -->

              <!-- <div class="md:col-span-4 md:mt-0 mt-8">
              <ul class="list-none md:text-end text-center pr-5"> -->
              <!-- <li class="inline"><a href="#" target="_blank" class="size-8 inline-flex items-center text-center justify-center text-base font-normal tracking-wide align-middle transition duration-500 ease-in-out border border-gray-800 rounded-md hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600"><i class="uil uil-linkedin" title="Linkedin"></i></a></li> -->
              <!-- <li class="inline"><a href="#" target="_blank" class="size-8 inline-flex items-center text-center justify-center text-base font-normal tracking-wide align-middle transition duration-500 ease-in-out border border-gray-800 rounded-md hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600"><i class="uil uil-facebook-f align-middle" title="facebook"></i></a></li> -->
              <!-- <li class="inline"><a href="#" target="_blank" class="size-8 inline-flex items-center text-center justify-center text-base font-normal tracking-wide align-middle transition duration-500 ease-in-out border border-gray-800 rounded-md hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600"><i class="uil uil-instagram align-middle" title="instagram"></i></a></li> -->
              <!-- <li class="inline"><a href="#" target="_blank" class="size-8 inline-flex items-center text-center justify-center text-base font-normal tracking-wide align-middle transition duration-500 ease-in-out border border-gray-800 rounded-md hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600"><i class="uil uil-twitter align-middle" title="twitter"></i></a></li> -->
              <!-- <li class="inline">
                  <a
                    href="mailto:support@astrowarp.net"
                    class="size-8 inline-flex items-center text-center justify-center text-base font-normal tracking-wide align-middle transition duration-500 ease-in-out border border-gray-800 rounded-md hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600"
                    ><i class="uil uil-envelope align-middle" title="email"></i
                  ></a>
                </li>
                <li class="inline">
                  <a
                    href="https://docs.astrowarp.net/"
                    target="_blank"
                    class="size-8 inline-flex items-center text-center justify-center text-base font-normal tracking-wide align-middle transition duration-500 ease-in-out border border-gray-800 rounded-md hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600"
                    ><i
                      class="uil uil-file align-middle"
                      title="customization"
                    ></i
                  ></a>
                </li> -->
              <!-- </ul> -->
              <!--end icon-->
              <!-- </div> -->
            </div>
          </div>
        </div>
        <!--end container-->
      </div>
    </footer>
    <!--end footer-->
    <!-- Footer End -->
  </div>
</template>

<script>
export default {
  name: "footerVue",
  data() {
    return {
      date: new Date().getFullYear(),
    };
  },
  mounted() {
    this.scrollToTop();
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 767px) {
  .footer-list > div {
    margin-left: 60px;
  }
}
.index-svg{
    fill: #ffffff90;
  }
  .index-svg:hover{
    fill: #ffffff;
  }
  ul li{
    margin-inline-start: 0px;
  }

</style>