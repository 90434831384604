<template>
    <div>
   <!-- Start -->
    <!--  dark:bg-slate-800 -->
   <section class="relative md:py-24 py-16 bg-[#131419] text-white" id="features">
            <div class="container px-2 mx-auto">
                <div class="grid grid-cols-1 pb-8 text-center">
                    <h3 style="font-size:24px;" class="mb-4 text-[30px] lg:text-5xl font-bold">Why Choose AstroWarp</h3>
                    <!-- for Individuals and Small Enterprises <br/> -->
                    <p style="font-size:12px;" class="text-[#939DB8]  md:text-xl  lg:text-xl max-w-xl mx-auto">Define Your Own Network with GL.iNet Routers</p>
                </div><!--end grid-->

                <div class="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-4 gap-[30px]">
                    <!-- Content -->
                    <div v-for="(item,index) in servicesData" :key="index" class="p-8 py-10 why-item group relative  mt-4 transition duration-500 ease-in-out rounded-2xl overflow-hidden text-left">
                        <div class="relative overflow-hidden text-transparent my-3">
                            <!-- <i data-feather="hexagon" class="size-28 fill-green-100 rotate-[30deg]"></i> -->
                            <!-- <div class="absolute top-2/4 -translate-y-2/4 start-0 end-0 pl-9 text-blue-600 rounded-xl transition duration-500 ease-in-out text-3xl flex align-middle justify-left items-center"> -->
                                <i :class=item.icon class="text-6xl text-[#2463EB]"></i>

                            <!-- </div> -->
                        </div>
                       
                        <div class="mt-6">
                            <div  class="text-2xl font-bold h5 transition duration-500 ease-in-out text-[#FFFFFF]">{{item.title}}</div>
                            <!-- <span   class="text-lg h5 transition duration-500 ease-in-out hover:text-violet-600">{{item.title}}</span> -->
                            <p class="text-[#94A2B8] transition duration-500 ease-in-out mt-3 text-lg why-desc" v-html="item.desc"></p>
                            <router-link :to="'/feature/'+index"   class="text-[#939DB8] pt-4  font-bold text-xl inline-block my-4 after:bg-blue-600  transition duration-500">
                   Learn More  <i class="uil uil-arrow-right"></i
                    >
                </router-link>
                        </div>
                    </div>
                    <!-- Content -->
                
                </div><!--end grid-->
                <!-- <div v-if="isHome" class="text-center pt-8" style="font-weight:600;font-size: 18px;">
                        <router-link to="/shop/compatible-devices"   class="hover:text-blue-600 dark:hover:text-blue-600 after:bg-blue-600 dark:text-white transition duration-500">
                   Learn More about the Compatible Devices <i class="uil uil-arrow-right"></i
                    >
                </router-link>
                </div> -->

            </div><!--end container-->
        </section><!--end section-->
        <!-- End  -->
    </div>
</template>

<script>
 import feather from 'feather-icons'

    export default {
        name: "featuresVue",
        props:["isHome"],
        data() {
            return {
                servicesData :[
                    {
                        id:1,
                        icon:'uil uil-expand-arrows',
                        title:'Simple and Flexible',
                        desc:"Provide convenient and efficient network configuration features, along with support for managing router network resources access permissions, enabling you to easily create and manage your network anytime, anywhere. "
                    },
                    {
                        id:2,
                        icon:'uil uil-shield',
                        title:'Secure and Private',
                        desc:`AstroWarp prioritizes your privacy by offering the exclusive 'Work from Home' service  that utilizes your home router as the internet exit, ensuring safe and encrypted remote work or internet access.`
                    },
                    {
                        id:3,
                        icon:'uil uil-thumbs-up',
                        title:'Stable and Reliable',
                        desc:'By directly incorporating our independently developed routers into the network, AstroWarp ensures seamless integration for improved stability, enhanced performance, and optimized network reliability across all connected devices.'
                    },
                    
                   
                ]
            }
        },
        components:{

        },
        mounted() {
        feather.replace();
        }
    }
</script>

<style lang="scss" scoped>
.why-item{
    border: 1px solid #939DB8;
    border-radius: 30px;
    background-color: #131419;
}

.why-desc{
   
   @media (min-width: 768px)  {
    height: 240px;

   }
   @media (min-width: 1440px)  {
    height: 180px

   }
   @media (min-width: 1920px)  {
    height: 160px

   }
}
</style>