<template>
  <div class="hello">
    <NavbarVue />
    <!-- Hero Start -->
    <section
      class="py-36 lg:py-56 w-full table relative bg-[url('../../assets/images/bg/bg_1.jpg')] bg-center bg-no-repeat"
      style="background-size: cover;"
      id="home"
    >
      <div class="absolute inset-0" style="background: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0))"></div>
      <div class="container lg:grid ">
        <div class="grid grid-cols-1 pb-8 lg:pl-20">
          <h3
            class="font-bold lg:leading-normal leading-normal text-[30px] lg:text-5xl mb-5 text-white"
          >
            Explore Your Network <br />
            Possibilities Today
          </h3>
          <p class="text-lg font-medium text-white/60">
            AstroWarp is officially launched.<br />Now Available for Use and App Download.
          </p>

          <div class="subcribe-form mt-6">
            <!--  border-blue-600  -->
            <button
              style="border-color: #2463eb"
              class="relative py-2 px-5 bg-blue-600 inline-block font-normal tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center text-white rounded-full absolute h-11 top-0.5 end-0.5  hover:bg-blue-700 border-blue-600 hover:border-blue-700"
            >
              <a href="/shop/price">Try for Free</a
              ><i class="uil uil-arrow-right"></i>
            </button>

            <!-- <form
              class="relative max-w-xl"
              action="https://gl-inet.us16.list-manage.com/subscribe/post?u=8ed66b6ad326f7c2f2f6127f4&amp;id=b48dc644fc&amp;f_id=003c7de0f0"
              method="post"
           
              target="_blank"
              name="mc-embedded-subscribe-form"
            >
              <input
                class="rounded-full bg-white/90 h-12 w-full py-3 ps-5 pe-40 text-slate-950 dark:text-white"
                type="email"
                name="EMAIL"
                id="mce-EMAIL"
                placeholder="Email Address"
                required=""
                value=""
              />
              <button
                type="submit"
                name="subscribe"

                class="py-2 px-5 inline-block font-normal tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-blue-600 hover:bg-blue-700 border-blue-600 hover:border-blue-700 text-white rounded-full absolute h-11 top-0.5 end-0.5"
              >
                Join Now <i class="uil uil-arrow-right"></i>
              </button>
            </form> -->
            <!--end form-->
          </div>
          <div class="text-left">
            <h5
              class="font-medium rounded-md py-2 text-center mt-8 inline-block text-white/60  hover:text-white"
            >
              Join Our Community
            </h5>
            <ul class="list-none text-left">
              <li class="inline pr-2">
                <a
                  href="https://www.reddit.com/r/AstroWarp/"
                  target="_blank"
                  class="inline-flex text-white/60 items-center text-center justify-center text-base font-normal tracking-wide align-middle transition duration-500 ease-in-out"
                >
                <svg class="text-white/60" viewBox="0 0 1024 1024" width="32" height="32"><path d="M938.666667 490.666667A106.666667 106.666667 0 0 0 832 384a104.96 104.96 0 0 0-64 22.613333A486.4 486.4 0 0 0 551.68 341.333333l26.453333-151.466666a10.24 10.24 0 0 1 10.24-8.96h2.986667L725.333333 221.866667a85.333333 85.333333 0 1 0 17.92-61.44l-134.826666-39.253334a85.333333 85.333333 0 0 0-20.906667-2.986666 74.666667 74.666667 0 0 0-73.386667 61.866666L486.826667 341.333333A491.52 491.52 0 0 0 256 406.613333 104.96 104.96 0 0 0 192 384 106.24 106.24 0 0 0 128 576.853333 165.546667 165.546667 0 0 0 128 597.333333c0 141.226667 170.666667 256 384 256s384-114.773333 384-256a165.546667 165.546667 0 0 0 0-20.48 106.666667 106.666667 0 0 0 42.666667-86.186666z m-640 42.666666A64 64 0 1 1 362.666667 597.333333 64 64 0 0 1 298.666667 533.333333z m350.293333 189.44a21.76 21.76 0 0 1-10.666667 13.653334 268.373333 268.373333 0 0 1-252.586666 0 21.76 21.76 0 0 1-10.666667-13.653334 22.186667 22.186667 0 0 1 3.413333-17.066666l12.373334-17.493334A20.906667 20.906667 0 0 1 418.133333 682.666667a216.32 216.32 0 0 0 187.733334 0 20.906667 20.906667 0 0 1 27.306666 6.826666l12.373334 17.493334a22.186667 22.186667 0 0 1 3.413333 15.786666zM661.333333 597.333333a64 64 0 1 1 64-64 64 64 0 0 1-64 64z"  class="index-svg"></path></svg>
                  <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M0 256C0 114.6 114.6 0 256 0S512 114.6 512 256s-114.6 256-256 256L37.1 512c-13.7 0-20.5-16.5-10.9-26.2L75 437C28.7 390.7 0 326.7 0 256zM349.6 153.6c23.6 0 42.7-19.1 42.7-42.7s-19.1-42.7-42.7-42.7c-20.6 0-37.8 14.6-41.8 34c-34.5 3.7-61.4 33-61.4 68.4l0 .2c-37.5 1.6-71.8 12.3-99 29.1c-10.1-7.8-22.8-12.5-36.5-12.5c-33 0-59.8 26.8-59.8 59.8c0 24 14.1 44.6 34.4 54.1c2 69.4 77.6 125.2 170.6 125.2s168.7-55.9 170.6-125.3c20.2-9.6 34.1-30.2 34.1-54c0-33-26.8-59.8-59.8-59.8c-13.7 0-26.3 4.6-36.4 12.4c-27.4-17-62.1-27.7-100-29.1l0-.2c0-25.4 18.9-46.5 43.4-49.9l0 0c4.4 18.8 21.3 32.8 41.5 32.8zM177.1 246.9c16.7 0 29.5 17.6 28.5 39.3s-13.5 29.6-30.3 29.6s-31.4-8.8-30.4-30.5s15.4-38.3 32.1-38.3zm190.1 38.3c1 21.7-13.7 30.5-30.4 30.5s-29.3-7.9-30.3-29.6c-1-21.7 11.8-39.3 28.5-39.3s31.2 16.6 32.1 38.3zm-48.1 56.7c-10.3 24.6-34.6 41.9-63 41.9s-52.7-17.3-63-41.9c-1.2-2.9 .8-6.2 3.9-6.5c18.4-1.9 38.3-2.9 59.1-2.9s40.7 1 59.1 2.9c3.1 .3 5.1 3.6 3.9 6.5z"/></svg> -->
                </a>
              </li>

              <li class="inline">
                <a
                  href="https://link.gl-inet.com/AW-homepage-discord"
                  target="_blank"
                  class="inline-flex items-center text-center justify-center text-base font-normal tracking-wide align-middle transition duration-500 ease-in-out"
                >
                <svg viewBox="0 0 1280 1024"  width="32" height="32"><path d="M1049.062 139.672a3 3 0 0 0-1.528-1.4A970.13 970.13 0 0 0 808.162 64.06a3.632 3.632 0 0 0-3.846 1.82 674.922 674.922 0 0 0-29.8 61.2 895.696 895.696 0 0 0-268.852 0 619.082 619.082 0 0 0-30.27-61.2 3.78 3.78 0 0 0-3.848-1.82 967.378 967.378 0 0 0-239.376 74.214 3.424 3.424 0 0 0-1.576 1.352C78.136 367.302 36.372 589.38 56.86 808.708a4.032 4.032 0 0 0 1.53 2.75 975.332 975.332 0 0 0 293.65 148.378 3.8 3.8 0 0 0 4.126-1.352A696.4 696.4 0 0 0 416.24 860.8a3.72 3.72 0 0 0-2.038-5.176 642.346 642.346 0 0 1-91.736-43.706 3.77 3.77 0 0 1-0.37-6.252 502.094 502.094 0 0 0 18.218-14.274 3.638 3.638 0 0 1 3.8-0.512c192.458 87.834 400.82 87.834 591 0a3.624 3.624 0 0 1 3.848 0.466 469.066 469.066 0 0 0 18.264 14.32 3.768 3.768 0 0 1-0.324 6.252 602.814 602.814 0 0 1-91.78 43.66 3.75 3.75 0 0 0-2 5.222 782.11 782.11 0 0 0 60.028 97.63 3.728 3.728 0 0 0 4.126 1.4A972.096 972.096 0 0 0 1221.4 811.458a3.764 3.764 0 0 0 1.53-2.704c24.528-253.566-41.064-473.824-173.868-669.082zM444.982 675.16c-57.944 0-105.688-53.174-105.688-118.478s46.818-118.482 105.688-118.482c59.33 0 106.612 53.64 105.686 118.478 0 65.308-46.82 118.482-105.686 118.482z m390.76 0c-57.942 0-105.686-53.174-105.686-118.478s46.818-118.482 105.686-118.482c59.334 0 106.614 53.64 105.688 118.478 0 65.308-46.354 118.482-105.688 118.482z" class="index-svg"></path></svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <!-- <div class="right-img rounded-2xl">
            <div class="w-full h-full p-3 bg-white rounded-xl">
              <img src="../assets/img/index1.png" alt="" srcset="" />
            </div>
        </div> -->
        <!--end grid-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <div
      :class="isActive ? 'fixed' : 'hidden'"
      class="bg-black/[0.9] top-0 left-0 bottom-0 w-[100%] h-[100%] z-999"
    >
      <div class="h-[100%] flex items-center justify-center">
        <div class="relative inline-block">
          <iframe
            src="https://www.youtube.com/embed/S_CGed6E610"
            width="700"
            height="500"
            frameborder="0"
          ></iframe>
          <a
            class="mdi mdi-close text-white absolute top-1 right-1 text-xl"
            @click="toggle"
          ></a>
        </div>
      </div>
    </div>
    <!-- Hero End -->
    <!-- Hero End -->
    <AboutVue />
    <featuresVue :isHome="true" />
    <cta />
    <!-- <Blogs /> -->
    <!-- <pricing /> -->

    <!-- <GetInTouch /> -->
    <Footer />
  </div>
</template>
  
<script>
import NavbarVue from "../components/Navbar.vue";
import AboutVue from "../components/AboutComponent.vue";
import featuresVue from "../components/FeaturesComponent.vue";
import cta from "../components/Cta.vue";
// import Blogs from "../components/BlogComponent.vue";
// import pricing from "../components/PricingComponent.vue";
// import GetInTouch from "@/components/GetInTouchComponent.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "IndexPage",
  data() {
    return {
      isActive: false,
    };
  },
  components: {
    NavbarVue,
    AboutVue,
    featuresVue,
    cta,
    // pricing,
    // Blogs,
    // GetInTouch,
    Footer,
  },

  methods: {
    toggle() {
      if (!this.isActive) {
        this.isActive = true;
      } else {
        this.isActive = false;
      }
    },
  },
};
</script>
  
<style scoped lang="scss">
.right-img {
  border: 1px solid #ccc;
  padding: 10px;
  // border-radius: 20px;
  @media (max-width: 1023px) {
    display: none;
  }
  img {
    // border-radius: 20px;
    height: 100%;
  }

}
.index-svg{
    fill: #ffffff90;
  }
  .index-svg:hover{
    fill: #ffffff;
  }
</style>
  